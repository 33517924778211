import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M5140 7934 c-68 -6 -88 -13 -86 -26 2 -19 -13 -20 -66 -8 -41 10 -61
8 -155 -15 -59 -14 -135 -35 -168 -47 -33 -11 -67 -21 -75 -23 -8 -1 -14 -8
-13 -13 2 -7 -4 -9 -14 -5 -9 4 -14 2 -10 -3 3 -5 -7 -10 -21 -9 -15 0 -26 -5
-25 -11 1 -6 -9 -10 -22 -8 -14 1 -25 -2 -25 -6 0 -5 -9 -12 -20 -15 -11 -4
-18 -11 -14 -16 4 -6 11 -6 20 2 11 9 14 8 14 -4 0 -9 6 -14 13 -11 7 2 21 -2
32 -10 20 -15 20 -15 -3 -16 -13 0 -32 5 -43 10 -23 13 -26 -2 -4 -24 32 -31
7 -50 -30 -22 -11 8 -25 12 -32 9 -7 -3 -16 2 -19 11 -3 9 -16 16 -28 15 -11
0 -15 -3 -8 -6 6 -2 12 -11 12 -18 0 -16 25 -26 47 -18 12 5 15 2 10 -10 -5
-14 -2 -16 21 -11 20 4 23 3 13 -5 -12 -8 -10 -12 12 -22 15 -7 27 -16 27 -21
0 -5 4 -7 9 -4 5 4 21 -8 36 -25 20 -24 29 -29 38 -20 19 19 33 12 53 -28 11
-23 24 -37 31 -34 7 3 13 -2 13 -11 0 -18 15 -22 25 -6 3 6 13 10 21 10 8 0
14 -4 14 -10 0 -5 -9 -10 -20 -10 -11 0 -23 -7 -26 -15 -4 -8 -12 -15 -19 -15
-7 0 -15 -3 -17 -7 -3 -8 -67 -64 -112 -98 -48 -36 -110 -90 -132 -112 -12
-13 -25 -23 -30 -23 -5 0 -23 -15 -39 -32 -17 -18 -52 -51 -78 -72 l-49 -40
-60 62 -60 61 -32 -35 -33 -36 -40 46 c-23 26 -54 57 -70 69 -27 21 -27 21
-13 1 25 -34 110 -142 186 -233 38 -47 78 -98 88 -113 l17 -27 -59 -63 c-115
-122 -226 -261 -321 -400 -17 -27 -34 -48 -37 -48 -3 0 -54 100 -114 223 -85
172 -115 226 -135 235 -14 6 -25 10 -25 7 0 -37 153 -432 174 -448 7 -6 4 -7
-7 -3 -22 7 -45 -29 -150 -236 -44 -88 -83 -155 -87 -151 -9 10 -80 268 -80
290 0 9 -3 14 -6 10 -6 -6 20 -168 36 -222 5 -16 21 -76 36 -131 l27 -102 -36
-73 c-58 -120 -66 -146 -42 -142 5 1 31 4 57 8 40 5 49 10 54 31 10 41 37 25
52 -31 32 -115 148 -373 226 -500 l25 -40 -70 -82 c-39 -46 -68 -83 -64 -83
24 0 125 66 145 94 14 20 30 36 35 36 15 0 48 -58 40 -70 -3 -6 -2 -10 4 -10
5 0 12 6 14 13 3 7 6 5 6 -5 1 -12 -7 -18 -21 -19 -13 0 -17 -3 -10 -6 6 -2
12 -11 12 -18 0 -15 118 -168 184 -239 25 -26 44 -52 41 -56 -8 -14 34 1 46
16 15 18 34 18 53 -2 22 -21 20 -60 -3 -76 -10 -7 -48 -41 -84 -75 l-66 -61
92 -68 c101 -75 218 -145 323 -194 l69 -32 50 22 c28 12 56 24 63 27 17 7 15
-19 -3 -43 -8 -10 -15 -24 -15 -31 0 -18 171 -69 327 -98 171 -32 394 -42 547
-26 96 11 294 46 303 54 1 2 -1 26 -6 55 -8 53 2 95 14 57 4 -11 10 -26 14
-34 10 -20 81 -43 114 -37 72 14 347 141 347 160 0 6 -10 34 -22 63 -17 38
-31 56 -53 65 -16 7 -33 12 -37 10 -5 -1 -8 3 -8 8 0 6 -6 10 -13 10 -18 0
-54 83 -39 88 7 2 12 8 12 13 0 5 -8 4 -19 -2 -15 -10 -28 -5 -83 34 -36 26
-70 47 -76 47 -6 0 -12 4 -14 9 -6 16 -273 191 -276 181 -6 -17 -22 -11 -22 8
0 9 -5 24 -10 32 -8 13 -13 13 -38 -2 l-29 -18 -1 48 c-1 33 -7 52 -20 62 -87
72 -97 82 -72 77 l25 -6 -22 18 c-16 14 -19 21 -10 30 8 8 7 11 -6 11 -9 0
-17 3 -17 8 0 4 -14 23 -30 42 -19 22 -30 29 -30 19 0 -8 5 -20 11 -26 7 -7 6
-12 -5 -16 -10 -4 -13 -1 -10 7 3 8 1 17 -4 20 -6 3 -8 14 -6 24 3 10 -8 29
-31 50 -25 24 -35 43 -36 65 -2 48 0 59 19 95 16 31 16 34 0 43 -39 22 -36 26
175 227 53 50 97 98 97 106 0 7 -7 20 -17 27 -9 8 -61 64 -116 124 -55 61
-118 130 -141 155 -23 25 -48 53 -56 63 -12 17 -12 18 5 15 13 -2 22 5 28 23
8 21 5 30 -17 55 -15 16 -35 37 -43 47 -9 9 -24 17 -32 17 -17 0 -71 -45 -71
-58 0 -4 14 -20 30 -36 17 -16 30 -33 30 -37 0 -5 20 -29 45 -55 41 -42 51
-72 20 -60 -9 3 -13 -5 -12 -29 1 -19 -6 -42 -15 -52 -10 -11 -19 -47 -23 -95
-4 -43 -11 -78 -16 -78 -5 0 -9 -11 -9 -24 0 -35 -10 -56 -27 -56 -10 0 -14
-10 -12 -32 4 -63 -1 -128 -10 -128 -6 0 -12 15 -14 33 l-4 32 -1 -32 c-1 -21
-7 -33 -16 -33 -10 0 -13 -11 -11 -37 1 -21 0 -26 -2 -10 -7 46 -23 30 -23
-23 0 -39 -4 -53 -20 -62 -11 -7 -20 -21 -20 -30 0 -10 -5 -18 -11 -18 -6 0
-19 -10 -29 -22 l-18 -23 -53 49 c-30 27 -103 98 -164 159 -107 107 -164 146
-214 147 -17 0 -43 -19 -85 -62 -89 -92 -298 -316 -342 -368 -59 -68 -101
-110 -110 -110 -24 0 -210 284 -305 466 -34 65 -65 121 -68 124 -3 3 -7 22 -8
43 -2 32 10 59 74 165 90 150 220 341 303 446 57 72 61 75 85 64 14 -6 25 -16
25 -22 0 -6 5 -4 11 4 8 12 9 10 4 -7 -8 -27 -4 -33 137 -233 159 -224 236
-319 265 -326 63 -16 92 2 248 155 293 286 312 300 329 255 3 -8 10 -14 16
-14 6 0 9 4 6 9 -9 14 146 181 168 181 6 0 36 -26 66 -58 169 -178 305 -325
308 -333 2 -5 11 -9 21 -9 15 0 198 175 429 411 l103 106 -78 89 c-42 49 -120
133 -173 187 -96 99 -136 157 -126 183 6 16 36 19 36 4 0 -13 29 -2 67 26 17
13 36 24 41 24 5 0 17 9 27 20 10 11 24 20 31 20 7 0 17 6 21 13 4 7 25 26 46
41 40 30 84 90 106 144 15 35 32 44 24 12 -3 -11 -9 -20 -12 -20 -4 0 -8 -6
-8 -12 0 -7 -4 -26 -8 -42 -6 -21 -5 -27 4 -22 6 4 11 18 11 32 0 15 6 24 15
24 10 0 15 11 16 33 0 29 1 30 9 10 5 -13 15 -23 21 -23 8 0 8 4 -1 15 -7 9
-10 18 -7 22 4 3 2 12 -4 20 -9 9 -8 13 0 13 6 0 11 -4 11 -9 0 -5 9 -11 20
-14 13 -3 20 -14 21 -29 0 -21 1 -21 8 -3 8 20 -1 29 -83 89 -14 10 -26 22
-26 26 0 4 40 32 90 61 84 51 91 57 115 113 18 42 23 62 15 71 -30 37 -274
168 -408 220 -83 33 -78 33 -272 -42 -122 -48 -132 -53 -112 -60 6 -3 12 -12
12 -21 0 -13 -2 -14 -9 -4 -6 9 -10 1 -15 -25 -9 -59 -15 -64 -63 -52 l-40 11
-2 66 c-1 37 -6 71 -11 76 -16 16 -12 24 13 28 20 3 22 9 25 74 l3 71 -33 7
c-41 8 -308 15 -368 10z m-50 -18 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m260 -56 c-19 -13 -30 -13 -30 0 0 6 10 10 23 10
18 0 19 -2 7 -10z m-790 -190 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m-80 -50 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m580 -94 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9
4 6 10 25 14 25 6z m-375 -26 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10
9 10 6 0 13 -4 16 -10z m93 -162 c7 -10 20 -18 27 -18 8 0 26 -17 39 -37 13
-21 33 -49 45 -62 12 -14 19 -31 16 -39 -3 -7 4 -25 15 -39 11 -14 17 -29 14
-34 -3 -5 1 -6 9 -3 16 6 97 -65 97 -84 0 -7 5 -12 11 -12 6 0 3 -7 -6 -17
-14 -14 -18 -14 -26 -2 -6 10 -9 11 -9 1 0 -7 7 -15 15 -18 12 -5 13 -10 4
-20 -6 -8 -16 -11 -21 -8 -5 3 -12 -4 -15 -15 l-6 -22 -19 21 c-10 11 -22 20
-26 20 -4 0 -25 17 -47 38 -22 21 -69 60 -105 87 -36 27 -81 62 -100 78 -19
15 -40 31 -45 35 -6 4 -27 20 -48 35 l-38 29 38 26 c61 43 102 64 113 57 6 -3
7 1 4 10 -5 13 -1 16 22 13 16 -2 35 -11 42 -20z m186 -194 c23 -9 20 -24 -4
-24 -11 0 -20 7 -20 15 0 17 2 18 24 9z m-354 -87 c19 -18 87 -80 150 -137 63
-58 149 -138 190 -177 41 -40 78 -73 82 -73 5 0 8 -5 8 -12 0 -24 81 -88 110
-88 16 0 32 -5 35 -10 4 -6 10 -8 15 -5 5 3 11 1 15 -4 3 -5 -3 -14 -12 -20
-10 -6 -12 -11 -5 -11 6 0 12 -9 12 -20 0 -11 4 -20 8 -20 4 0 8 -17 9 -38 2
-32 -2 -38 -19 -39 -53 -4 -139 -70 -261 -200 -27 -29 -53 -50 -58 -47 -5 3
-9 1 -9 -4 0 -6 -39 -50 -86 -98 -58 -59 -88 -84 -93 -76 -5 9 -13 6 -27 -9
-19 -22 -19 -22 0 -15 19 7 19 6 1 -14 -19 -21 -41 -28 -29 -8 4 7 3 8 -5 4
-6 -4 -9 -12 -6 -17 4 -5 -1 -9 -9 -9 -8 0 -23 -11 -34 -25 -29 -36 -61 -32
-97 14 -16 21 -64 80 -106 132 -71 88 -185 240 -321 428 -32 44 -58 85 -58 91
0 35 312 352 475 483 11 9 31 25 45 36 33 27 39 26 80 -12z m923 -77 c36 -28
67 -65 67 -79 0 -18 -139 -153 -182 -177 -9 -5 -34 -26 -57 -46 -22 -21 -43
-38 -47 -38 -3 0 -19 -10 -36 -21 -24 -17 -32 -19 -38 -9 -16 26 5 110 26 110
8 0 14 7 14 16 0 10 6 14 14 11 13 -5 31 18 27 36 0 4 5 10 13 13 8 3 12 12
10 19 -3 7 4 18 15 25 12 6 21 17 21 25 0 8 7 15 15 15 8 0 15 6 15 13 0 19
43 67 60 68 11 0 11 2 2 6 -19 7 -4 23 16 16 14 -5 15 -3 5 8 -19 20 -16 34 4
18 10 -8 26 -21 36 -29z m-1405 -705 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7
10 -15 7 -18z m1139 -201 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-642 -183 c3 -6 -4 -15 -15 -21 -20 -11 -21 -10 -10 9 12 23 16 25 25 12z
m585 -88 c-4 -42 -5 -44 -8 -15 -3 30 2 62 10 62 2 0 1 -21 -2 -47z m-1639
-123 c4 -43 3 -45 -26 -51 -16 -4 -22 -7 -12 -8 15 -1 16 -3 5 -15 -11 -12
-14 -10 -21 10 -7 18 -3 40 14 80 25 62 35 58 40 -16z m1689 -360 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-860 -102 c0 -7
-14 -14 -32 -15 -29 -2 -30 -1 -12 12 24 18 44 19 44 3z m-50 -46 c0 -4 10
-18 23 -32 12 -14 45 -52 72 -85 70 -84 171 -185 184 -185 16 0 14 -4 -23 -43
-24 -25 -36 -33 -44 -25 -8 8 -9 5 -5 -11 5 -18 1 -21 -25 -21 -28 0 -48 -20
-43 -43 1 -5 -6 -6 -16 -3 -56 16 -76 18 -69 7 4 -6 12 -9 17 -6 16 11 10 -12
-11 -36 -10 -13 -19 -18 -20 -11 0 14 -30 17 -30 2 0 -5 -9 -10 -20 -10 -11 0
-20 -6 -20 -14 0 -21 -20 -30 -33 -17 -6 6 -16 11 -22 11 -5 0 -3 -5 5 -10 12
-8 11 -10 -7 -10 -17 0 -23 -6 -24 -22 0 -22 -1 -22 -10 -3 -7 15 -8 10 -4
-18 4 -21 8 -46 11 -55 5 -17 -8 8 -33 64 -8 17 -23 35 -33 40 -30 14 -210
184 -210 199 0 6 80 91 177 188 149 148 180 174 195 166 10 -5 18 -13 18 -17z
m610 -72 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-34 -45 c-8 -8 -18 -15 -22 -15 -3 0 -1 7 6 15 7 8 17 15 22 15 5 0 3
-7 -6 -15z m472 -74 c24 -20 29 -33 9 -25 -11 4 -42 44 -34 44 2 0 14 -8 25
-19z m-609 -62 c-20 -12 -22 -4 -7 20 11 18 14 19 16 5 2 -9 -2 -20 -9 -25z
m-869 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m630 -184 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15
-2 15 -4z m-100 -71 c-33 -19 -50 -18 -26 0 11 8 27 15 35 15 10 -1 7 -6 -9
-15z m-60 -72 c0 -15 2 -16 10 -3 8 13 10 13 10 -2 0 -21 -39 -60 -53 -51 -5
3 -4 8 3 13 7 4 9 17 4 33 -5 21 -2 27 10 27 9 0 16 -8 16 -17z m-250 -153 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m820
-205 c52 -8 138 -19 190 -24 74 -7 110 -16 164 -41 37 -17 64 -35 60 -40 -20
-17 -81 -30 -142 -30 -50 0 -106 12 -236 51 -94 28 -175 55 -179 59 -5 4 -6
18 -2 30 5 16 12 20 28 16 12 -3 65 -12 117 -21z m-167 -54 c-13 -11 -23 -24
-23 -30 0 -6 -5 -11 -10 -11 -6 0 -4 13 5 30 9 17 23 30 33 30 14 -1 13 -4 -5
-19z m47 -1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-50 -60 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z"/>
<path d="M4780 7270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4880 7190 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5285 6719 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M4265 7650 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4317 7654 c-3 -3 -3 -12 0 -20 4 -10 0 -12 -13 -8 -14 5 -16 4 -8
-5 15 -16 48 -3 34 14 -5 6 -7 15 -3 18 3 4 4 7 1 7 -2 0 -7 -3 -11 -6z"/>
<path d="M4224 7624 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M6446 7519 c-15 -12 -25 -27 -22 -35 3 -7 0 -15 -7 -18 -9 -3 -8 -8
3 -16 12 -9 11 -10 -7 -5 -17 5 -24 -1 -37 -27 -10 -19 -13 -34 -7 -36 15 -5
88 -69 186 -165 l90 -87 58 61 c31 34 57 67 57 73 0 7 -39 50 -86 97 -98 96
-94 92 -155 142 l-46 37 -27 -21z"/>
<path d="M7334 7318 c-84 -56 -190 -118 -202 -118 -7 0 -12 -4 -12 -8 0 -5
-47 -39 -105 -76 -58 -37 -103 -71 -100 -76 5 -7 -18 -21 -40 -25 -5 0 -18 -5
-27 -9 -21 -10 -24 -31 -4 -23 8 3 20 -1 26 -9 17 -20 37 -17 43 6 4 17 14 19
73 19 127 -1 369 -20 372 -29 2 -6 21 -10 43 -10 31 0 39 -4 39 -18 0 -19 75
-62 108 -62 9 0 23 -10 31 -21 10 -18 15 -19 22 -8 7 11 14 9 36 -13 l27 -28
-22 -30 c-13 -16 -18 -30 -12 -30 5 0 21 21 35 48 15 26 23 38 20 28 -6 -20
-6 -20 15 -1 17 16 20 26 15 55 -8 41 -56 139 -91 185 -13 17 -24 35 -24 40 0
4 -6 13 -13 19 -8 6 -42 46 -76 89 -36 44 -67 74 -73 71 -6 -4 -8 -3 -5 3 4 5
-6 22 -22 37 l-28 27 -49 -33z m371 -458 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M6260 7230 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6825 6022 c6 -4 19 -24 29 -45 14 -26 22 -34 28 -25 6 10 8 10 8 1
0 -7 5 -13 11 -13 6 0 9 7 5 15 -3 8 -1 15 3 15 5 0 11 -6 14 -12 2 -7 7 -10
11 -6 4 3 -3 22 -16 42 -20 31 -28 36 -63 36 -22 0 -35 -4 -30 -8z"/>
<path d="M5205 5900 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7481 5831 c-12 -16 -21 -35 -21 -43 0 -10 3 -9 10 2 6 9 10 10 10 3
0 -19 41 -38 50 -23 6 9 10 7 14 -7 4 -11 16 -29 28 -39 20 -19 21 -19 15 1
-3 11 -8 34 -12 50 -6 33 -45 85 -63 85 -6 0 -20 -13 -31 -29z"/>
<path d="M6775 5810 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5545 4870 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6575 4820 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6624 4803 c3 -6 -1 -13 -10 -15 -14 -3 -14 -5 3 -20 18 -16 19 -16
18 7 -1 14 -5 28 -10 32 -4 5 -5 3 -1 -4z"/>
<path d="M6671 4724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6845 4700 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6706 4673 c27 -46 73 -78 93 -66 9 6 15 16 14 22 -2 6 1 11 6 11 5
0 12 7 15 16 4 11 1 14 -14 12 -10 -2 -26 6 -34 17 -14 19 -15 19 -34 2 -18
-16 -21 -16 -35 -2 -24 24 -30 18 -11 -12z"/>
<path d="M6862 4673 c-7 -2 -17 -13 -22 -24 -8 -14 -7 -17 2 -14 11 4 45 47
36 44 -2 0 -9 -3 -16 -6z"/>
<path d="M7204 4646 c-3 -8 -12 -12 -20 -9 -11 4 -93 -54 -124 -87 -3 -3 -25
-21 -50 -40 -25 -19 -47 -37 -50 -40 -16 -17 -157 -113 -228 -155 l-83 -50 43
-40 c127 -117 126 -116 148 -103 11 7 20 16 20 20 0 5 5 8 11 8 27 0 235 185
350 312 l70 77 -16 33 c-42 88 -59 106 -71 74z"/>
<path d="M3815 4600 c21 -24 26 -25 19 -6 -3 8 -13 17 -22 21 -13 5 -13 2 3
-15z"/>
<path d="M3990 4560 c-12 -8 -10 -10 8 -10 20 0 21 -2 10 -20 -7 -11 -19 -20
-26 -20 -7 0 -15 -7 -19 -16 -5 -15 -7 -15 -15 0 -5 9 -13 16 -19 16 -5 0 -21
11 -35 25 -14 14 -30 25 -36 25 -6 0 0 -9 13 -20 13 -12 37 -32 52 -47 31 -28
21 -32 102 37 22 19 32 31 21 27 -10 -4 -24 -3 -30 2 -7 6 -17 6 -26 1z"/>
<path d="M3933 4553 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"/>
<path d="M3950 4530 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2545 3836 c-163 -71 -179 -335 -25 -414 45 -23 106 -26 168 -7 21 7
23 5 16 -21 -20 -84 -112 -213 -164 -229 -11 -4 -20 -11 -20 -16 0 -36 159 49
235 125 103 105 146 224 126 349 -18 107 -57 169 -133 208 -44 23 -156 25
-203 5z m149 -59 c18 -25 21 -45 24 -172 l4 -144 -36 -6 c-45 -9 -82 13 -102
60 -16 39 -19 173 -4 224 21 74 74 92 114 38z"/>
<path d="M4270 3695 c-19 -11 -21 -14 -7 -15 15 0 17 -15 17 -165 0 -118 4
-175 14 -199 44 -105 197 -102 240 4 30 76 11 181 -39 214 -32 21 -84 20 -107
-1 -17 -15 -18 -13 -18 65 0 72 -2 83 -22 96 -27 19 -45 20 -78 1z m176 -210
c17 -42 18 -161 1 -195 -7 -14 -21 -25 -32 -25 -11 0 -25 11 -32 25 -17 33
-17 202 -1 218 23 23 48 13 64 -23z"/>
<path d="M5150 3695 c-19 -11 -21 -14 -8 -15 16 0 18 -19 20 -217 3 -202 4
-218 21 -221 10 -2 29 -1 43 2 l24 6 0 214 c0 182 -2 217 -16 230 -19 20 -51
20 -84 1z"/>
<path d="M5630 3695 c-19 -11 -21 -14 -7 -15 15 0 17 -15 17 -167 0 -147 2
-172 20 -208 23 -48 53 -65 112 -65 81 0 138 66 138 160 0 52 -26 115 -55 134
-32 21 -84 20 -107 -1 -17 -15 -18 -13 -18 64 0 65 -3 83 -18 96 -22 21 -49
21 -82 2z m176 -210 c17 -42 18 -161 1 -195 -7 -14 -21 -25 -32 -25 -11 0 -25
11 -32 25 -17 33 -17 202 -1 218 23 23 48 13 64 -23z"/>
<path d="M6270 3695 c-19 -11 -21 -14 -8 -15 16 0 18 -19 20 -217 3 -202 4
-218 21 -221 10 -2 29 -1 43 2 l24 6 0 214 c0 182 -2 217 -16 230 -19 20 -51
20 -84 1z"/>
<path d="M4860 3663 c-76 -39 -113 -100 -118 -193 -9 -147 62 -229 197 -230
l54 0 -6 -41 c-10 -79 -87 -120 -135 -72 -24 24 -42 23 -42 -2 0 -35 101 -47
167 -20 76 32 93 71 93 216 l0 109 25 0 c14 0 25 5 25 10 0 6 -37 10 -90 10
-53 0 -90 -4 -90 -10 0 -5 11 -10 25 -10 25 0 25 -1 25 -80 l0 -80 -44 0 c-37
0 -48 5 -70 30 -32 38 -40 80 -34 181 4 68 9 86 34 122 58 82 110 87 128 13 4
-21 15 -40 22 -43 22 -8 44 16 44 48 0 38 -37 61 -103 66 -42 4 -62 -1 -107
-24z"/>
<path d="M6655 3680 c-33 -13 -65 -55 -71 -92 -8 -44 9 -77 79 -160 77 -92 87
-108 87 -149 0 -50 -28 -82 -69 -82 -36 0 -46 10 -62 66 -7 26 -15 37 -26 35
-46 -9 -40 -78 10 -108 62 -38 177 -16 217 40 50 70 30 136 -73 249 -75 81
-91 124 -62 167 25 39 57 29 74 -24 14 -45 34 -53 54 -20 34 54 -81 110 -158
78z"/>
<path d="M2966 3645 c-36 -37 26 -78 67 -45 18 15 20 22 11 37 -15 24 -57 28
-78 8z"/>
<path d="M7754 3646 c-12 -33 0 -51 35 -51 41 0 62 25 42 49 -17 20 -70 21
-77 2z"/>
<path d="M2946 3535 c-17 -13 -18 -15 -3 -15 15 0 17 -15 17 -129 0 -141 5
-155 57 -149 l28 3 3 130 c4 168 2 175 -46 175 -20 0 -46 -7 -56 -15z"/>
<path d="M3202 3533 c-103 -50 -121 -191 -34 -263 42 -35 94 -40 148 -14 41
20 58 40 23 28 -70 -24 -123 12 -134 91 -9 58 0 106 26 134 28 30 52 23 60
-17 4 -18 10 -36 13 -40 12 -12 41 15 44 41 6 51 -77 74 -146 40z"/>
<path d="M3490 3532 c-79 -39 -109 -133 -71 -218 34 -75 137 -96 211 -44 37
26 34 36 -4 16 -35 -18 -83 -6 -108 27 -16 20 -19 28 -22 58 -1 6 31 29 69 53
54 33 71 48 73 68 6 53 -77 76 -148 40z m78 -52 c2 -30 -2 -38 -27 -53 -16 -9
-34 -17 -40 -17 -14 0 -14 38 0 79 9 27 16 32 38 29 22 -3 26 -9 29 -38z"/>
<path d="M3732 3540 c-12 -5 -27 -21 -33 -34 -15 -33 -1 -67 51 -124 28 -30
40 -53 40 -73 0 -37 -20 -46 -72 -32 -46 12 -48 9 -5 -17 43 -26 107 -26 141
1 20 16 26 29 26 60 0 34 -8 48 -56 100 -60 66 -69 101 -25 107 30 5 52 -15
31 -28 -11 -7 -12 -12 -3 -21 16 -16 43 -4 43 20 0 42 -78 65 -138 41z"/>
<path d="M3939 3530 c-15 -12 -18 -18 -7 -14 14 5 17 -8 20 -114 2 -101 6
-123 22 -141 25 -28 63 -27 106 3 l35 23 10 -21 c13 -26 47 -32 87 -15 25 10
27 14 14 21 -13 7 -16 30 -16 131 0 132 -5 147 -50 147 -26 0 -77 -29 -52 -30
9 0 12 -28 12 -105 0 -100 -1 -105 -25 -121 -48 -31 -55 -16 -55 114 0 78 -4
122 -12 130 -18 18 -63 14 -89 -8z"/>
<path d="M5394 3540 c-30 -12 -71 -64 -79 -101 -21 -96 42 -199 123 -199 60 0
88 14 118 60 52 78 30 201 -42 235 -35 16 -87 19 -120 5z m94 -49 c13 -47 8
-187 -7 -205 -21 -25 -64 -17 -72 14 -19 70 -5 204 23 221 19 12 48 -3 56 -30z"/>
<path d="M6012 3540 c-12 -5 -27 -21 -34 -35 -9 -21 -8 -28 6 -44 10 -11 24
-17 32 -14 16 6 19 43 4 43 -19 0 -10 29 11 35 31 10 67 -2 74 -25 11 -35 -4
-56 -57 -81 -82 -38 -98 -55 -98 -100 0 -50 27 -79 74 -79 18 0 44 9 58 19 24
20 25 20 41 0 19 -22 55 -25 81 -6 17 12 17 14 2 20 -13 5 -16 23 -16 111 0
57 -3 112 -6 121 -15 38 -114 58 -172 35z m98 -176 c0 -25 -5 -54 -10 -65 -13
-23 -55 -25 -64 -3 -20 51 8 114 50 114 22 0 24 -4 24 -46z"/>
<path d="M6983 3534 c-74 -32 -110 -127 -79 -207 20 -51 55 -78 110 -84 41 -5
108 16 121 37 8 13 3 13 -30 0 -39 -15 -88 9 -109 55 -10 20 -15 40 -11 43 3
4 37 25 73 48 51 31 68 47 70 66 6 51 -72 74 -145 42z m77 -58 c0 -24 -7 -35
-31 -48 -17 -10 -35 -18 -40 -18 -12 0 -11 40 2 78 19 54 69 46 69 -12z"/>
<path d="M7186 3535 c-17 -13 -18 -15 -3 -15 15 0 17 -15 17 -129 0 -141 5
-155 57 -149 l28 3 3 113 3 113 35 16 c28 14 39 15 55 5 16 -11 19 -9 19 8 0
13 7 20 18 20 15 0 21 -15 34 -90 26 -147 70 -207 135 -185 47 17 86 108 91
217 4 82 3 88 -15 88 -18 0 -20 -7 -19 -87 1 -90 -13 -147 -40 -157 -22 -9
-44 36 -59 121 -8 43 -17 86 -20 96 -10 23 -52 33 -89 19 -20 -8 -34 -8 -40
-2 -17 17 -40 11 -69 -16 -16 -14 -30 -24 -31 -23 -2 2 -9 14 -15 27 -14 26
-65 30 -95 7z"/>
<path d="M7740 3535 c-19 -11 -21 -14 -7 -15 15 0 17 -13 17 -127 0 -71 4
-134 9 -141 5 -8 22 -12 42 -10 l34 3 3 139 c2 93 -1 143 -9 152 -15 18 -57
17 -89 -1z"/>
<path d="M7994 3536 c-68 -30 -109 -126 -85 -198 6 -19 25 -48 42 -64 26 -25
38 -29 87 -29 44 0 65 5 90 24 18 13 31 26 28 29 -3 2 -12 -1 -20 -8 -28 -22
-88 -15 -113 14 -42 49 -42 167 0 206 30 28 51 22 55 -17 5 -53 62 -51 62 3 0
48 -78 69 -146 40z"/>
<path d="M8281 3533 c-81 -40 -111 -133 -71 -220 30 -67 112 -92 186 -57 50
24 49 32 -4 27 -53 -6 -83 13 -102 63 -13 32 -6 40 65 76 56 28 75 47 75 74 0
50 -82 70 -149 37z m77 -53 c2 -30 -2 -37 -29 -52 -17 -10 -35 -18 -40 -18
-12 0 -11 40 2 79 9 27 16 32 38 29 22 -3 26 -9 29 -38z"/>
<path d="M8506 3525 c-40 -37 -34 -63 36 -146 46 -55 51 -95 12 -105 -14 -4
-37 -1 -51 5 -39 18 -39 6 0 -18 45 -27 106 -28 141 0 20 16 26 29 26 60 0 34
-8 48 -56 100 -60 66 -69 101 -25 107 30 5 52 -15 31 -28 -11 -7 -12 -12 -3
-21 16 -16 43 -4 43 20 0 52 -107 70 -154 26z"/>
<path d="M2890 2525 l0 -495 2665 0 2665 0 0 495 0 495 -2665 0 -2665 0 0
-495z m945 270 c14 -13 25 -31 25 -40 0 -20 -44 -65 -63 -65 -21 0 -54 31 -62
57 -4 17 1 30 19 48 31 31 50 32 81 0z m1551 -155 l6 -25 18 23 c17 21 22 22
60 12 56 -15 75 -48 75 -130 0 -73 -18 -110 -68 -140 -28 -17 -30 -17 -62 1
-28 17 -35 18 -42 6 -4 -8 -30 -22 -58 -30 l-50 -14 3 221 c2 121 5 222 7 224
1 2 25 8 51 15 l49 12 3 -75 c1 -41 5 -86 8 -100z m-1762 107 c29 -36 31 -44
31 -120 0 -107 -26 -166 -105 -241 -32 -31 -65 -56 -73 -56 -8 0 -30 -3 -48
-7 l-34 -6 33 37 c32 36 82 110 82 121 0 3 -9 5 -20 5 -31 0 -98 37 -115 64
-40 61 -26 159 28 200 38 29 95 45 151 43 32 -2 44 -9 70 -40z m514 -101 c25
-13 52 -52 52 -75 0 -5 -23 -14 -51 -21 -47 -12 -51 -11 -65 9 -22 31 -34 17
-34 -39 0 -39 4 -52 22 -64 27 -19 79 -21 96 -4 9 9 12 9 12 0 0 -7 -15 -28
-33 -48 -26 -28 -42 -37 -78 -41 -87 -10 -129 44 -129 162 0 65 1 67 43 107
39 37 45 39 89 34 26 -3 60 -12 76 -20z m1840 0 c25 -13 52 -52 52 -75 0 -5
-23 -14 -51 -21 -47 -12 -51 -11 -65 9 -22 31 -34 17 -34 -39 0 -39 4 -52 22
-64 27 -19 79 -21 96 -4 9 9 12 9 12 0 0 -7 -15 -28 -33 -48 -26 -28 -42 -37
-78 -41 -87 -10 -129 44 -129 162 0 65 1 67 43 107 39 37 45 39 89 34 26 -3
60 -12 76 -20z m1411 -14 c21 -24 23 -32 17 -92 -3 -36 -6 -77 -6 -92 0 -22
-11 -35 -55 -64 -30 -21 -57 -35 -59 -33 -2 2 0 29 6 59 11 60 5 137 -12 170
-9 17 -14 19 -26 9 -10 -8 -14 -37 -14 -114 l0 -103 -42 -7 c-24 -3 -51 -9
-60 -11 -17 -5 -18 6 -18 129 0 150 -2 146 84 162 29 6 36 4 36 -9 0 -14 6
-12 38 8 44 29 77 25 111 -12z m-2987 18 c15 0 39 -10 53 -23 24 -20 26 -27
20 -69 -8 -52 -22 -62 -105 -76 l-45 -8 28 -18 c19 -13 38 -17 62 -13 42 7 44
-3 8 -43 -36 -40 -94 -50 -146 -25 -44 20 -57 54 -57 143 0 63 1 66 42 105 36
35 45 38 77 33 20 -3 48 -6 63 -6z m395 -4 c1 -1 -1 -15 -6 -32 -9 -32 -10
-32 -103 -20 -48 6 -57 5 -52 -7 8 -23 50 -37 107 -37 72 0 101 -21 94 -67 -9
-52 -33 -82 -85 -105 -77 -33 -216 -11 -205 33 6 22 5 22 74 9 37 -7 63 -7 86
1 47 15 31 29 -33 29 -143 0 -190 91 -91 175 l41 36 86 -7 c47 -4 86 -7 87 -8z
m219 -2 c-3 -9 -9 -46 -12 -83 -6 -66 -2 -82 33 -130 2 -3 14 6 28 19 23 22
25 31 25 112 l0 88 39 0 c22 0 46 3 55 6 14 5 16 -11 16 -138 l0 -145 -55 -10
c-53 -9 -55 -9 -55 12 0 34 -9 36 -60 10 -46 -23 -48 -23 -81 -7 -56 28 -68
77 -53 209 7 52 7 52 48 62 64 14 79 13 72 -5z m1261 6 c42 0 51 -3 47 -15 -4
-9 1 -18 10 -21 22 -8 46 -56 46 -89 0 -38 -36 -102 -68 -121 -15 -9 -40 -26
-55 -36 -35 -24 -75 -17 -133 23 -84 58 -81 136 8 222 38 37 55 47 71 43 12
-3 45 -6 74 -6z m388 -13 c11 -5 23 -15 27 -21 4 -6 17 0 34 17 22 23 33 27
58 22 42 -9 79 -36 86 -66 4 -13 5 -60 2 -105 l-4 -81 -47 -31 c-25 -18 -49
-32 -51 -32 -3 0 -4 41 -2 91 4 93 -9 149 -33 149 -20 0 -25 -24 -25 -115 0
-46 -3 -86 -7 -88 -5 -3 -29 -8 -54 -12 l-46 -7 -5 84 c-6 94 -26 149 -46 126
-6 -8 -13 -57 -14 -109 l-3 -94 -53 -5 c-30 -3 -55 -3 -56 0 -1 3 0 65 2 137
l4 133 36 10 c57 15 72 13 72 -12 0 -22 0 -22 27 4 28 28 50 29 98 5z m980 12
c49 -13 82 -42 90 -80 3 -15 1 -80 -5 -144 -12 -129 -29 -175 -80 -219 -41
-34 -68 -40 -118 -26 -71 19 -94 81 -46 125 l26 24 27 -20 c36 -26 86 -30 85
-6 0 9 -1 47 -2 84 l-2 67 -47 -46 c-47 -46 -49 -46 -80 -33 -28 12 -32 19
-38 67 -9 72 4 102 90 205 13 15 48 16 100 2z m-3785 -118 c0 -66 3 -126 6
-134 7 -19 -18 -33 -73 -40 l-43 -5 0 108 0 107 -26 -5 c-22 -4 -25 -2 -22 17
2 18 17 28 68 45 36 13 71 24 78 25 9 1 12 -29 12 -118z m1817 -46 c27 -7 37
-34 24 -67 -14 -38 -35 -50 -75 -42 -39 7 -42 14 -25 65 6 19 13 40 15 47 2 7
12 11 21 8 10 -2 28 -7 40 -11z m1371 -14 c32 -40 -19 -107 -72 -95 -17 3 -32
8 -34 9 -2 2 2 28 10 59 l13 56 35 -6 c19 -3 41 -14 48 -23z"/>
<path d="M5392 2568 c-7 -7 -12 -31 -12 -55 0 -37 4 -45 26 -53 14 -6 27 -10
30 -10 8 0 4 68 -7 99 -12 34 -19 37 -37 19z"/>
<path d="M3480 2685 c-20 -52 -10 -104 28 -148 12 -13 17 -15 24 -5 12 21 9
96 -6 132 -19 46 -34 53 -46 21z"/>
<path d="M4326 2568 c-13 -49 -8 -58 29 -58 30 0 35 3 35 23 0 24 -27 57 -47
57 -6 0 -13 -10 -17 -22z"/>
<path d="M6176 2584 c-9 -23 -7 -69 4 -98 10 -27 64 -61 80 -51 6 3 10 29 10
56 0 42 -5 55 -29 80 -33 32 -56 37 -65 13z"/>
<path d="M7560 2515 c0 -41 2 -75 4 -75 6 0 66 99 66 108 0 8 -52 42 -64 42
-3 0 -6 -34 -6 -75z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
